<template>
    <div class="content-box">
        <div class="container">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>协同管理</el-breadcrumb-item>
            <el-breadcrumb-item>协同设计</el-breadcrumb-item>
            <el-breadcrumb-item>文档资料传输</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="button-gutter">
            <el-upload
                :headers="tokenData"
                class="upload-demo"
                :action="actions"
                :data="uploadData"
                :with-credentials="false"
                :before-upload="beforeUpload"
                :on-success="handleSuccess"
            >
                <el-button type="primary">点击上传</el-button>
            </el-upload>
        </div>

        <el-table :data="tableData" border max-height="600">
            <el-table-column align="center" width="50" label="序号">
                <template slot-scope="scope">
                    {{ scope.$index + 1 }}
                </template>
            </el-table-column>
            <el-table-column prop="archivesName" align="center" label="文档资料名称"> </el-table-column>
            <el-table-column prop="company" align="center" label="公司"> </el-table-column>
            <el-table-column prop="timeUpdated" align="center" label="传输时间"> </el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-button style="margin-right: 12px" size="mini" type="primary" plain @click="Handsee(scope.row, scope.$index)">
                        下载
                    </el-button>
                    <el-popconfirm
                        confirm-button-text="确定"
                        cancel-button-text="取消"
                        confirm-button-type="danger"
                        icon="el-icon-info"
                        icon-color="red"
                        title="确定删除吗？"
                        @confirm="deleteFile(scope.row, scope.$index)"
                    >
                        <el-button type="danger" size="mini" plain slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
      
        <el_pagination :total="total" :num="num" @page-limit="page_limit"></el_pagination>

</div>
</div>

</template>

<script>
import {  fileList, fileRemove, documentUpload } from '@/api/file';
import { getUserList } from '@/api/user.js';
import { fileInfornmation, deleteInformation } from '@/api/document';


export default {
    name: 'FileTransfer',
    components: {},
    data() {
        return {
            dialogVisible: false,
            tableData: [],
            uploadPerson: '',
            userOptions: [],
            actions: process.env.VUE_APP_BASE_API + `/documentation/upload`,
            addForm: {},
            pageSize: 10,
            currentPage: 1,
            total: 0,
            num: 0,
            userName: localStorage.getItem('name')
        };
    },
    mounted() {
        this.getUserList();
        this.getFileList();
    },
    computed: {
        baseApi() {
            return process.env.VUE_APP_BASE_API;
        },
        tokenData() {
            return {
                Authorization: window.localStorage.getItem('token')
            };
        },
        uploadData() {
            return {
                dispatcher: this.addForm.dispatcher,
                recipient: this.addForm.recipient
            };
        }
    },

    methods: {
        // 分页子集传过来的数据  page = 页码  limit = 条数
        // 分页子集传过来的数据  page = 页码  limit = 条数
        page_limit(list) {
            console.log(list);
            let { page, limit } = list;
            this.currentPage = page;
            this.pageSize = limit;
            this.tableData = [];
            this.getFileList();
        },

        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        },
        //获取上传人下拉框
        async getUserList() {
            let res = await getUserList();

            if (res.code == 200) {
                //  console.log(res.data);

                let userData = {};
                let userList = [];
                res.data.forEach((item) => {
                    //    console.log(item)
                    userData.lable = item.name;
                    userData.value = item.name;
                    if (item.username !== this.userName) {
                        userList.push(userData);
                    }
                    userData = {};
                });
                this.userOptions = userList;
            }
        },

        //   获取文档资料表
        async getFileList() {
            let limit = this.pageSize;
            let page = this.currentPage;
            let res = await fileInfornmation({
                limit,
                page
            });

            if (res.code == 200) {
             //   console.log(res.data);
                this.tableData = res.data;
                this.total = res.total;
                this.num = parseInt(this.total / limit + 1);
            }
        },
        //取消
        Cancel() {
            this.dialogVisible = false;
            this.addForm = {};
        },

        //上传文档弹框
        SendData() {
            this.dialogVisible = true;
            this.addForm.dispatcher = localStorage.getItem('name');
            this.uploadPerson = localStorage.getItem('username');
            console.log(this.addForm);
        },

        //上传完成刷新页面
        submitData(addForm) {
            this.$message.success('上传成功');
            this.dialogVisible = false;
            this.addForm = {};
            this.getFileList();
        },
        // 上传文件大小
        beforeUpload(file) {
            const isLt10M = file.size / 1024 / 1024 < 10;
            if (!isLt10M) {
                this.$message.error('上传文件大小不能超过 10MB!');
            }
            return isLt10M;
        },
        blur() {
            console.log(this.$refs[cascader]);
        },
        //上传文件
        handleSuccess(res, file) {
            if (res.code == 200) {
                this.$message.success('上传成功');
                this.getFileList();
            } else {
                this.$message.error('上传失败');
            }
        },

        async deleteFile(row, $index) {
            let id = row.id;
            console.log(id);
            const res = await deleteInformation({
                id
            });
            if (res.code === 200) {
                this.$message.success('删除成功');
            } else {
                this.$message.error('删除失败');
            }
            await this.getFileList();
        },
        async Handsee(row, $index) {
            let id = row.id;
            const res = await documentUpload({ id });
            let url = window.URL.createObjectURL(new Blob([res]));
            // const url = new Blob([res])
            const link = document.createElement('a');
            link.style.display = 'none';
            link.href = url;
            link.download = row.archivesName + row.archivesPath;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};
</script>


<style lang="scss" scoped>
.button-gutter {
    margin: 20px 0;
}
::v-deep .el-dialog__header {
    text-align: center;
}
.footer {
    width: 20%;

    margin: 0 auto;
}
</style>